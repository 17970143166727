import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
import { CodeBlock } from '../../../src/components/DigitalGarden';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "PostgreSQL"`}</p>



    <h2 {...{
      "id": "postgresql",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#postgresql",
        "aria-label": "postgresql permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PostgreSQL`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#grant-all-privileges-to-user"
        }}>{`Grant all privileges to user`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#connect-to-database-by-some-user"
        }}>{`Connect to database by some user`}</a></li>
    </ol>
    <h3 {...{
      "id": "grant-all-privileges-to-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#grant-all-privileges-to-user",
        "aria-label": "grant all privileges to user permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Grant all privileges to user`}</h3>
    <ol>
      <li parentName="ol">{`Connect to psql cli`}</li>
    </ol>
    <CodeBlock mdxType="CodeBlock">{`psql -U <user>`}</CodeBlock>
    <blockquote>
      <p parentName="blockquote">{`Super user - postgres.
You will be prompted to enter a password.`}</p>
    </blockquote>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Connect to database`}</li>
    </ol>
    <CodeBlock mdxType="CodeBlock">{`\c <database_name>`}</CodeBlock>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Grant privileges`}</li>
    </ol>
    <CodeBlock mdxType="CodeBlock">{`GRANT ALL PRIVILEGES ON ALL TABLES IN SCHEMA public to <user_name>;`}</CodeBlock>
 
-----------------------------------------------------
    <h3 {...{
      "id": "connect-to-database-by-some-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-to-database-by-some-user",
        "aria-label": "connect to database by some user permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect to database by some user`}</h3>
    <CodeBlock mdxType="CodeBlock">{`\c <database_name> <user_name>`}</CodeBlock>
    <p>{`You will be prompted to enter a password.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      